import React, { useEffect } from 'react';

import Header from '../layout/Header';
import { Footer } from '../layout/Footer';

export default function MobilePos(props) {

  useEffect(() => {
    const imagesToPreload = [
      "images/MAD.avif",
      "images/mobile-pos-1.avif",
    ];

    imagesToPreload.forEach((image) => {
      const preloadLink = document.createElement("link");
      preloadLink.rel = "preload";
      preloadLink.as = "image";
      preloadLink.href = image;
      document.head.appendChild(preloadLink);

      return () => {
        document.head.removeChild(preloadLink);
      };
    });
  }, []);

  return (
    <>
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Mobile & POS Solutions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* our-services-section */}
        <section className="ttm-row clearfix ">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-text-center res-991-mb-20" style={{ marginTop: 5 }}>
                <div className="ttm_single_image-wrapper">
                  <picture>
                    <img
                      className="img-fluid image-radious"
                      src="images/MAD.avif"
                      title="single-img-five"
                      alt="single-img-five"
                      loading='lazy'
                    />
                  </picture>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2">

                  <div className="section-title with-desc clearfix">
                    <div className="title-desc text-justify">
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5> Mobile App and POS Solutions:</h5>
                        </div>
                        <p className='mb-2'>At the core of modern business success is the ability to adapt and innovate. Our Mobile App and POS Solutions are designed to enhance your customer experience, optimize operational workflows, and support your business growth. </p>
                        {/* <p> From concept to
                              deployment, we deliver end-to-end website development services that align with your brand and objectives. </p> */}
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Mobile Application Development:</h5>
                        </div>
                        <p className='mb-2'>We specialize in creating robust, user-friendly mobile applications for both iOS and Android platforms. Our approach covers the entire app development process—from crafting intuitive UI/UX designs to building scalable, feature-rich applications. We focus on delivering seamless functionality and ensure quality through rigorous testing. Our maintenance services ensure that your app stays up-to-date and continues to perform at its best, adapting to evolving user needs and technological advancements. </p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Custom POS Solutions:</h5>
                        </div>
                        <p className='mb-2'>Our custom Point of Sale (POS) solutions are engineered to improve transaction efficiency and simplify business operations. By providing real-time data tracking and secure payment processing, we streamline how businesses manage their sales, inventory, and customer interactions. Our POS systems are designed with ease of use in mind, enabling quick adaptation by your staff while ensuring a smooth, secure transaction experience for your customers. Whether in retail, hospitality, or other sectors, our POS systems are built to enhance operational efficiency and support business growth.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* our-solution-section */}
        <section className="ttm-row clearfix section1-content">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-mb-20">
                <div className="section-title with-desc clearfix">
                  <div className="title-header mb-10">
                    <h2 className="title">
                      Our Solutions
                    </h2>
                    <div className="under-title"></div>
                  </div>
                  <div className="title-desc text-justify pt-4">
                    <div className="title-desc">
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Telecom:</h5>
                        </div>
                        <p className='mb-2'>Our solutions are designed to streamline and simplify complex business processes. We offer CRM support to improve customer interactions, seamless recharge voucher redemption for hassle-free transactions, payment integration for secure and efficient processing, account management for organized operations, and transaction reporting to provide actionable business insights.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Digital Goods Sales:</h5>
                        </div>
                        <p className='mb-2'>we create solutions that enable seamless international recharge processes, efficient recharge voucher redemption, and detailed payment history reporting. These features ensure a smooth, user-friendly experience for both businesses and customers, driving engagement and operational success.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2" style={{ marginTop: 8 }}>
                  <div className="ttm_single_image-wrapper">
                    <picture>
                      <img
                        className="img-fluid image-radious"
                        src="images/mobile-pos-1.avif"
                        title="single-img-five"
                        alt="single-img-five"
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row clearfix section2-content">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-mb-20">
                <div className="section-title with-desc clearfix">
                  <div className="title-header mb-10">
                    <h2 className="title">
                      Our Solutions
                    </h2>
                    <div className="under-title"></div>
                  </div>
                  <div className="ttm_single_image-wrapper pt-4">
                    <picture>
                      <img
                        className="img-fluid image-radious"
                        src="images/mobile-pos-1.avif"
                        title="single-img-five"
                        alt="single-img-five"
                        loading='lazy'
                      />
                    </picture>
                  </div>

                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2" style={{ marginTop: 8 }}>
                  <div className="title-desc text-justify">
                    <div className="title-desc">
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Telecom:</h5>
                        </div>
                        <p className='mb-2'>Our solutions are designed to streamline and simplify complex business processes. We offer CRM support to improve customer interactions, seamless recharge voucher redemption for hassle-free transactions, payment integration for secure and efficient processing, account management for organized operations, and transaction reporting to provide actionable business insights.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Digital Goods Sales:</h5>
                        </div>
                        <p className='mb-2'>we create solutions that enable seamless international recharge processes, efficient recharge voucher redemption, and detailed payment history reporting. These features ensure a smooth, user-friendly experience for both businesses and customers, driving engagement and operational success.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

