import React, { Component } from 'react';
import { Header } from './layout/Header';
import Home3_mainpage from './Home3_mainpage';
import CarouselPage from './banner/Home1_banner';
import { Footer } from './layout/Footer';

export class Home3 extends Component {

  state = {
    showServiceSection: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showServiceSection: true });
    }, 500);
  }

  render() {
    return (

      <div>
        <Header />
        <CarouselPage />
        {this.state.showServiceSection && (
          <>
            <Home3_mainpage />
            <Footer />
          </>
        )}
      </div>
    )
  }
}


export default Home3;
