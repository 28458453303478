import React from 'react';
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

export default function CareerItem({ jobItem }) {
  const { title, experience_level, location } = jobItem.attributes;

  return (
    <Card className="list-box col-lg-5 col-md-8 col-sm-10 col-12 image-radious">
      <div className="ttm_single_image-wrapper">
        <div className="main-location"><h5>India</h5></div>
      </div>
      <Card.Body className="p-0">
        <Link to={"/job/" + jobItem.id} className="job-detail">
          <h2 className="job-title h-100">
            {title}
            {experience_level && ' | ' + experience_level}
            {location && ' | ' + location}
          </h2>
        </Link>
      </Card.Body>
      <Card.Footer className="p-0" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
        <Link to={"/job/" + jobItem.id} className="job-detail">More</Link>
      </Card.Footer>
    </Card>
    // <div className="list-box col-lg-5 col-md-8 col-sm-10 col-12">
    //   <div className="main-location"><h5>India</h5></div>
    //   <Link to={"/job/" + jobItem.id} className="job-detail">
    //     <h2 className="job-title h-100">
    //       { title } 
    //       { experience_level && ' | '+ experience_level }
    //       { location && ' | '+ location }
    //     </h2>
    //   </Link>
    //   <Link to={"/job/" + jobItem.id} className="job-detail">More</Link>
    // </div>
  );
}