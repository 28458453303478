import React, { Component, useEffect } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer, MDBRow } from
  "mdbreact";
import { Link } from 'react-router-dom';

const CarouselPage = () => {

  useEffect(() => {
    const imagesToPreload = [
      "images/bannerimage1.avif",
      "images/bannerimage2.avif",
      "images/bannerimage3.avif",
    ];

    imagesToPreload.forEach((image) => {
      const preloadLink = document.createElement("link");
      preloadLink.rel = "preload";
      preloadLink.as = "image";
      preloadLink.href = image;
      document.head.appendChild(preloadLink);

      return () => {
        document.head.removeChild(preloadLink);
      };
    });
  }, []);

  return (
    <MDBRow>
      <div className="container-xl cog-banner-view">
        <MDBCarousel
          activeItem={1}
          length={3}
          showControls={false}
          showIndicators={true}
          id="Home_banner"
        // interval={500000}
        >
          <MDBCarouselInner>
            <MDBCarouselItem className="banner4" itemId="2">
              <MDBView className="">
              <picture>
                <img class="d-block w-100 h-100 image-radious" src="images/bannerimage2.avif" alt="Second slide" loading="lazy" />
              </picture>
              </MDBView>
              <MDBCarouselCaption className="container-xl banner4">
                <div className="cog-banner ">
                  <div className="carousel-caption-inner banner4">
                    {/* <MDBAnimation className="slideInUp" count={1} >
                       
                       <p className=" mb-10"> <span className='cog'>COGN<span className='cog1'>iTUDE</span> </span> Technologies - Software & IT Consulting </p>
                     </MDBAnimation> */}
                    <MDBAnimation className="slideInUp" count={1} >

                      <h1 class="display-5 text-white ttm-h1-font">Building Resilient Solutions</h1>
                    </MDBAnimation>
                    <MDBAnimation className="slideInUp" count={1} >
                      <p className="ttm-p-font text-white">Transforming Challenges into Opportunities</p>
                    </MDBAnimation>
                    <MDBAnimation>
                    </MDBAnimation>
                  </div>
                </div>
              </MDBCarouselCaption>
            </MDBCarouselItem>
            <MDBCarouselItem className="banner5" itemId="1">
              <MDBView className="">
                <picture>
                  <img class="d-block w-100 h-100 image-radious" src="images/bannerimage1.avif" alt="Second slide" loading="lazy" />
                </picture>
              </MDBView>
              <MDBCarouselCaption className="container-xl banner5">
                <div className="cog-banner">
                  <div className="carousel-caption-inner banner5">
                    {/* <MDBAnimation className="slideInUp" count={1} >
                       
                       <p className=" mb-10"> <span className='cog'>COGN<span className='cog1'>iTUDE</span> </span> Technologies - Software & IT Consulting </p>
                     </MDBAnimation> */}
                    <MDBAnimation className="slideInUp" count={1} >
                      <h1 class="display-5 text-white ttm-h1-font">Bridging <br></br> Business <br></br> & Technology </h1>
                    </MDBAnimation>
                    <MDBAnimation className="slideInUp" count={1} >
                      <p className="ttm-p-font text-white"> Revolutionizing business <br></br> with the power of generative intelligence </p>

                    </MDBAnimation>
                  </div>
                </div>
              </MDBCarouselCaption>
            </MDBCarouselItem>
            <MDBCarouselItem className="banner6" itemId="3">
              <MDBView>
                <picture>
                  <img class="d-block w-100 h-100 image-radious" src="images/bannerimage3.avif" alt="Second slide" loading="lazy" />
                </picture>
              </MDBView>
              <MDBCarouselCaption className="container-xl banner5">
                <div className="cog-banner">
                  <div className="carousel-caption-inner banner5">
                    {/* <MDBAnimation className="slideInUp" count={1} >
                       
                       <p className=" mb-10"> <span className='cog'>COGN<span className='cog1'>iTUDE</span> </span> Technologies - Software & IT Consulting </p>
                     </MDBAnimation> */}
                    <MDBAnimation className="slideInUp" count={1} >
                      {/* <div className="d-flex flex-row-reverse"> */}
                      <h1 class="display-5 text-white ttm-h1-font">Mobile Apps for a <br></br> Connected World</h1>
                      {/* </div> */}
                    </MDBAnimation>
                    <MDBAnimation className="slideInUp" count={1} >
                      {/* <div className="d-flex flex-row-reverse"> */}
                      <p className="ttm-p-font text-white"> Unleashing power in the palm of your hands</p>
                      {/* </div> */}
                    </MDBAnimation>
                  </div>
                </div>
              </MDBCarouselCaption>
            </MDBCarouselItem>

          </MDBCarouselInner>
        </MDBCarousel>
      </div>
    </MDBRow>
  );
}

export default CarouselPage;