import React, { useEffect } from 'react';

import Header from '../layout/Header';
import { Footer } from '../layout/Footer';

export default function GenAIConsulting(props) {

  useEffect(() => {
    const imagesToPreload = [
      "images/ITC.avif",
      "images/IT-consulting-1.avif",
    ];

    imagesToPreload.forEach((image) => {
      const preloadLink = document.createElement("link");
      preloadLink.rel = "preload";
      preloadLink.as = "image";
      preloadLink.href = image;
      document.head.appendChild(preloadLink);

      return () => {
        document.head.removeChild(preloadLink);
      };
    });
  }, []);

  return (
    <>
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">IT & GenAI Consulting</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* our-services-section */}
        <section className="ttm-row clearfix ">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-text-center res-991-mb-20" style={{ marginTop: 5 }}>
                <div className="ttm_single_image-wrapper">
                  <picture>
                    <img
                      className="img-fluid image-radious"
                      src="images/ITC.avif"
                      title="single-img-five"
                      alt="single-img-five"
                      loading='lazy'
                    />
                  </picture>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2">

                  <div className="section-title with-desc clearfix">
                    <div className="title-desc text-justify">
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5> Innovative IT Consulting Powered by GENAI:</h5>
                        </div>
                        <p className='mb-2'>At the forefront of technological advancements, our GENAI Consulting Services are designed to help businesses unlock the transformative potential of Generative AI. We empower your organization with innovative solutions that drive efficiency, creativity, and growth. </p>
                        {/* <p> From concept to
                              deployment, we deliver end-to-end website development services that align with your brand and objectives. </p> */}
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>GENAI Consulting:</h5>
                        </div>
                        <p className='mb-2'>We provide expert guidance on implementing Generative AI technologies to address complex business challenges. From identifying GenAI use cases for your business to strategy development and execution, our solutions help you achieve meaningful outcomes and unlock new opportunities in your industry.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Future-Proof Strategies:</h5>
                        </div>
                        <p className='mb-2'>With our expertise, you gain a competitive edge through future-proof strategies. We work with you to design solutions using GenAI that not only meet current demands but also position your business for sustainable growth.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* our-solution-section */}
        <section className="ttm-row clearfix section1-content">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-mb-20">
                <div className="section-title with-desc clearfix">
                  <div className="title-header mb-10">
                    <h2 className="title">
                      Our Solutions
                    </h2>
                    <div className="under-title"></div>
                  </div>
                  <div className="title-desc text-justify pt-4">
                    <div className="title-desc">
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>GENAI Tools:</h5>
                        </div>
                        <p className='mb-2'>We provide advanced tools for Talent Management, including profile comparison with job descriptions, sorting CVs based on skills, and sorting CVs as per job descriptions to ensure the best candidate matches for your requirements. Our solutions also assist in creating compelling job descriptions, saving time and effort in recruitment processes.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Education and Training:</h5>
                        </div>
                        <p className='mb-2'> we enable businesses to develop effective learning programs through curriculum creation and training content creation, tailored to specific skills and goals. These solutions ensure a robust foundation for employee development and organizational success.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2" style={{ marginTop: 8 }}>
                  <div className="ttm_single_image-wrapper">
                    <picture>
                      <img
                        className="img-fluid image-radious"
                        src="images/IT-consulting-1.avif"
                        title="single-img-five"
                        alt="single-img-five"
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row clearfix section2-content">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-mb-20">
                <div className="section-title with-desc clearfix">
                  <div className="title-header mb-10">
                    <h2 className="title">
                      Our Solutions
                    </h2>
                    <div className="under-title"></div>
                  </div>
                  <div className="ttm_single_image-wrapper pt-4">
                    <picture>
                      <img
                        className="img-fluid image-radious"
                        src="images/IT-consulting-1.avif"
                        title="single-img-five"
                        alt="single-img-five"
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2" style={{ marginTop: 8 }}>
                  <div className="title-desc text-justify">
                    <div className="title-desc">
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>GENAI Tools:</h5>
                        </div>
                        <p className='mb-2'>We provide advanced tools for Talent Management, including profile comparison with job descriptions, sorting CVs based on skills, and sorting CVs as per job descriptions to ensure the best candidate matches for your requirements. Our solutions also assist in creating compelling job descriptions, saving time and effort in recruitment processes.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Education and Training:</h5>
                        </div>
                        <p className='mb-2'> we enable businesses to develop effective learning programs through curriculum creation and training content creation, tailored to specific skills and goals. These solutions ensure a robust foundation for employee development and organizational success.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

