import React, { useEffect } from 'react';

import Header from '../layout/Header';
import { Footer } from '../layout/Footer';

export default function ITSolutions(props) {

  useEffect(() => {
    const imagesToPreload = [
      "images/CAD.avif",
      "images/IT-Solution.avif",
    ];

    imagesToPreload.forEach((image) => {
      const preloadLink = document.createElement("link");
      preloadLink.rel = "preload";
      preloadLink.as = "image";
      preloadLink.href = image;
      document.head.appendChild(preloadLink);

      return () => {
        document.head.removeChild(preloadLink);
      };
    });
  }, []);

  return (
    <>
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">IT Solutions & Security Services</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* our-services-section */}
        <section className="ttm-row clearfix ">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-text-center res-991-mb-20" style={{ marginTop: 5 }}>
                <div className="ttm_single_image-wrapper">
                  <picture>
                    <img
                      className="img-fluid image-radious"
                      src="images/CAD.avif"
                      title="single-img-five"
                      alt="single-img-five"
                      loading='lazy'
                    />
                  </picture>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2">

                  <div className="section-title with-desc clearfix">
                    <div className="title-desc text-justify">
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Comprehensive IT Solutions with Security Services:</h5>
                        </div>
                        <p className='mb-2'>In today’s dynamic digital landscape, businesses need resilient and innovative IT solutions to stay ahead. Our comprehensive IT services are tailored to align with your business goals and include: </p>
                        {/* <p> From concept to
                              deployment, we deliver end-to-end website development services that align with your brand and objectives. </p> */}
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Custom Application Development:</h5>
                        </div>
                        <p className='mb-2'>Our team specializes in crafting bespoke applications powered by cutting-edge technologies like AI and machine learning. These solutions are designed to enhance operational efficiency, streamline processes, and foster growth. </p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Advanced Security Services:</h5>
                        </div>
                        <p className='mb-2'>In an era of increasing cyber threats, securing your digital assets is paramount. Our advanced security solutions provide comprehensive protection for your business, addressing vulnerabilities, safeguarding sensitive data, and ensuring compliance with industry standards.</p>
                        <p className='mb-2'>Our services include Network Security, Endpoint Security, Data Security, and Application Security, delivering a multi-layered defense strategy. We offer Security Monitoring and Threat Detection to proactively identify and mitigate risks, along with Vulnerability and Patch Management to keep your systems up-to-date and secure.</p>
                        <p className='mb-2'>
                          Our Compliance and Audit Management helps you meet regulatory requirements seamlessly. Additionally, we provide Backup and Disaster Recovery (DR) Security to ensure business continuity in the face of unexpected events.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Infrastructure Maintenance and Support:</h5>
                        </div>
                        <p className='mb-2'>We provide end-to-end management of your IT infrastructure, ensuring it's robust, scalable, and secure. From regular maintenance to addressing critical issues, our team ensures uninterrupted business operations.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Application Support:</h5>
                        </div>
                        <p className='mb-2'>Our application support services focus on delivering consistent performance and reliability. Whether it’s resolving issues, improving functionality, or providing updates, we keep your critical systems running smoothly.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* our-solution-section */}
        <section className="ttm-row clearfix section1-content">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-mb-20">
                <div className="section-title with-desc clearfix">
                  <div className="title-header mb-10">
                    <h2 className="title">
                      Our Solutions
                    </h2>
                    <div className="under-title"></div>
                  </div>
                  <div className="title-desc text-justify pt-4">
                    <div className="title-desc">
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Telecom Solutions:</h5>
                        </div>
                        <p className='mb-2'>We provide comprehensive telecom solutions to manage and optimize your operations efficiently. Our services include GSMA Gateway integration,  MNP (Mobile Number Portability), CRM support, recharge voucher management, payment integration, and account management. We deliver advanced reporting capabilities with Transaction Reports, CDR Reporting, and Voucher Sales Management for actionable insights. Additional features include SIM activation connectivity.</p>
                        <p className='mb-2'>With multi-lingual support, stock management (covering SIM numbers, mobile numbers, and vouchers), and distributor/retailer management, our solutions cater to every aspect of telecom operations. Enhanced functionalities like user notifications and alerts, SIM management, invoice generation, credit management, ticketing systems, and QR code generation ensure seamless and secure processes across the board.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Video Conferencing Solutions:</h5>
                        </div>
                        <p className='mb-2'>Our video conferencing solutions provide everything needed for smooth collaboration and effective communication. We offer features like meeting and webinar scheduling, Google Calendar integration, and Google Contacts integration to streamline event organization. With attendance capturing, payment integration, and seamless functionality, our solutions are designed to enhance productivity and simplify workflows.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Digital Goods Sales Solutions:</h5>
                        </div>
                        <p className='mb-2'>Our Digital Goods Sales solutions are tailored to improve customer experience and operational efficiency. They include international recharge services, recharge voucher redemption, and payment history reporting for transparency and convenience. Additionally, we offer voucher inventory management, voucher sales tracking, and features for implementing promotions and discounts, helping businesses drive revenue and customer loyalty.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2" style={{ marginTop: 8 }}>
                  <div className="ttm_single_image-wrapper">
                    <picture>
                      <img
                        className="img-fluid image-radious"
                        src="images/IT-Solution.avif"
                        title="single-img-five"
                        alt="single-img-five"
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row clearfix section2-content">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-mb-20">
                <div className="section-title with-desc clearfix">
                  <div className="title-header mb-10">
                    <h2 className="title">
                      Our Solutions
                    </h2>
                    <div className="under-title"></div>
                  </div>
                  <div className="ttm_single_image-wrapper pt-4">
                    <picture>
                      <img
                        className="img-fluid image-radious"
                        src="images/IT-Solution.avif"
                        title="single-img-five"
                        alt="single-img-five"
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2" style={{ marginTop: 8 }}>
                <div className="title-desc text-justify">
                    <div className="title-desc">
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Telecom Solutions:</h5>
                        </div>
                        <p className='mb-2'>We provide comprehensive telecom solutions to manage and optimize your operations efficiently. Our services include GSMA Gateway integration,  MNP (Mobile Number Portability), CRM support, recharge voucher management, payment integration, and account management. We deliver advanced reporting capabilities with Transaction Reports, CDR Reporting, and Voucher Sales Management for actionable insights. Additional features include SIM activation connectivity.</p>
                        <p className='mb-2'>With multi-lingual support, stock management (covering SIM numbers, mobile numbers, and vouchers), and distributor/retailer management, our solutions cater to every aspect of telecom operations. Enhanced functionalities like user notifications and alerts, SIM management, invoice generation, credit management, ticketing systems, and QR code generation ensure seamless and secure processes across the board.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Video Conferencing Solutions:</h5>
                        </div>
                        <p className='mb-2'>Our video conferencing solutions provide everything needed for smooth collaboration and effective communication. We offer features like meeting and webinar scheduling, Google Calendar integration, and Google Contacts integration to streamline event organization. With attendance capturing, payment integration, and seamless functionality, our solutions are designed to enhance productivity and simplify workflows.</p>
                      </div>
                      <div className='mb-4'>
                        <div className="title-header mb-2">
                          <h5>Digital Goods Sales Solutions:</h5>
                        </div>
                        <p className='mb-2'>Our Digital Goods Sales solutions are tailored to improve customer experience and operational efficiency. They include international recharge services, recharge voucher redemption, and payment history reporting for transparency and convenience. Additionally, we offer voucher inventory management, voucher sales tracking, and features for implementing promotions and discounts, helping businesses drive revenue and customer loyalty.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

