import React, { Component, useEffect } from "react";
import { Card } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default function Home3_mainpage() {

  useEffect(() => {
    const imagesToPreload = [
      "images/CAD.avif",
      "images/ITC.avif",
      "images/MAD.avif",
      "images/about-us.avif",
      "images/career.avif",
    ];

    imagesToPreload.forEach((image) => {
      const preloadLink = document.createElement("link");
      preloadLink.rel = "preload";
      preloadLink.as = "image";
      preloadLink.href = image;
      document.head.appendChild(preloadLink);

      return () => {
        document.head.removeChild(preloadLink);
      };
    });
  }, []);

  var slick_slider = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Data = [
    {
      image: 'images/CAD.avif',
      title: 'IT Solutions & Security Services',
      content: "We offer end-to-end IT solutions designed to meet your business needs, including Custom Application Development to create innovative, AI-powered solutions that drive growth and efficiency. Our Infrastructure Maintenance and Support ensures a robust, secure, and scalable IT environment, while our Application Support guarantees seamless performance and reliability of your critical systems. Our Security Services safeguard your digital assets with advanced measures to protect against threats and ensure compliance. Partner with us to optimize performance, enhance security, and achieve sustainable success.",
      buttonTitle: 'Read More',
      buttonLink: '/ourservices/IT-security'
    },
    {
      image: 'images/ITC.avif',
      title: 'IT & GenAI Consulting',
      content: 'We provide GENAI Consulting to help you harness the power of Generative AI, driving innovation and unlocking new opportunities for your business. Our custom-made IT management services address industry-specific challenges, delivering solutions that align with your unique needs and objectives. With a focus on optimizing your IT infrastructure for performance, security, and efficiency, we ensure your business stays ahead in a competitive landscape while achieving its goals seamlessly.',
      buttonTitle: 'Read More',
      buttonLink: '/ourservices/IT-consulting'
    },
    {
      image: 'images/MAD.avif',
      title: 'Mobile & POS Solutions',
      content: 'We design and develop innovative, user-friendly mobile applications for iOS and Android, offering comprehensive services including UI/UX design, development, testing, and maintenance. Additionally, we create customized Point of Sale (POS) applications to streamline transactions and enhance business operations, ensuring seamless and efficient solutions for your business needs.',
      buttonTitle: 'Read More',
      buttonLink: '/ourservices/mobile-solution'
    }
  ]

  const Data1 = [
    {
      image: 'images/CAD1.avif',
      title: 'IT Solutions & Security Services',
      content: "We offer end-to-end IT solutions designed to meet your business needs, including Custom Application Development to create innovative, AI-powered solutions that drive growth and efficiency. Our Infrastructure Maintenance and Support ensures a robust, secure, and scalable IT environment, while our Application Support guarantees seamless performance and reliability of your critical systems. Our Security Services safeguard your digital assets with advanced measures to protect against threats and ensure compliance. Partner with us to optimize performance, enhance security, and achieve sustainable success.",
      buttonTitle: 'Read More',
      buttonLink: '/ourservices/IT-security'
    },
    {
      image: 'images/ITC1.avif',
      title: 'IT & GenAI Consulting',
      content: 'We provide GENAI Consulting to help you harness the power of Generative AI, driving innovation and unlocking new opportunities for your business. Our custom-made IT management services address industry-specific challenges, delivering solutions that align with your unique needs and objectives. With a focus on optimizing your IT infrastructure for performance, security, and efficiency, we ensure your business stays ahead in a competitive landscape while achieving its goals seamlessly.',
      buttonTitle: 'Read More',
      buttonLink: '/ourservices/IT-consulting'
    },
    {
      image: 'images/MAD1.avif',
      title: 'Mobile & POS Solutions',
      content: 'We design and develop innovative, user-friendly mobile applications for iOS and Android, offering comprehensive services including UI/UX design, development, testing, and maintenance. Additionally, we create customized Point of Sale (POS) applications to streamline transactions and enhance business operations, ensuring seamless and efficient solutions for your business needs.',
      buttonTitle: 'Read More',
      buttonLink: '/ourservices/mobile-solution'
    }
  ]

  return (
    <div className="site-main">
      {/* service-section */}
      <section className="ttm-row service-section-style2 res-991-pb-0 clearfix service-content1">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  {/* <h5>We Make Connections</h5> */}
                </div>
              </div>
              <div className="section-title with-desc clearfix mb-4">
                <h2 className="title">
                  Our Services
                </h2>
                <div className="under-title mb-2"></div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            {Data?.map((item, key) => {
              return (
                <div className="col-md-6 col-lg-4 mb-2">
                  <Link to={item.buttonLink}>
                    <Card style={{ width: '18rem !important' }} className="featured-icon-box style6 m-2 image-radious">
                      <div className="ttm_single_image-wrapper">
                        <picture>
                          <Card.Img
                            variant="top"
                            src={item.image}
                            className="img-fluid image-radious"
                            title="single-img-five"
                            alt="single-img-five"
                            loading="lazy"
                          />
                        </picture>
                      </div>
                      <Card.Body className="p-0">
                        <div className="featured-title mt-4 mb-4">
                          <h5>{item.title}</h5>
                        </div>
                        <div className="featured-desc text-justify">
                          <p className="text justify-content">
                            {" "}
                            {item.content}
                          </p>
                        </div>
                      </Card.Body>
                      <Card.Footer className="p-0" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">
                          <Link to={item.buttonLink}> {item.buttonTitle}
                            <i className="ti ti-angle-double-right" />
                          </Link>
                        </a>
                      </Card.Footer>
                    </Card>
                  </Link>
                </div>
              )
            }
            )}
            {/* <div className="col-md-6 col-lg-4">
                <div className="featured-icon-box style6 m-2">
                  <div className="featured-content h-100">
                    <div className="ttm_single_image-wrapper">
                      <img
                        className="img-fluid"
                        src="images/mobile.jpg"
                        title="single-img-five"
                        alt="single-img-five"
                      />
                    </div>
                    <div className="featured-title mt-4 mb-4">
                      <h5>Mobile App Development</h5>
                    </div>
                    <div className="featured-desc text-justify">
                      <p>
                        We specialize in creating innovative mobile apps across
                        various platforms, enhancing user engagement and meeting
                        your specific requirements.
                      </p>
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-3">
                      <Link to="/our-services"> Read More </Link>
                      <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </section>
      <section className="ttm-row service-section-style2 res-991-pb-0 clearfix service-content2">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  {/* <h5>We Make Connections</h5> */}
                </div>
              </div>
              <div className="section-title with-desc clearfix mb-4">
                <h2 className="title">
                  Our Services
                </h2>
                <div className="under-title mb-2"></div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            {Data1?.map((item, key) => {
              return (
                <div className="col-md-6 col-lg-4 mb-2">
                  <Link to={item.buttonLink}>
                    <Card style={{ width: '18rem !important' }} className="featured-icon-box style6 m-2 image-radious">
                      <div className="ttm_single_image-wrapper">
                        <picture>
                          <Card.Img
                            variant="top"
                            src={item.image}
                            className="img-fluid image-radious"
                            title="single-img-five"
                            alt="single-img-five"
                            loading="lazy"
                          />
                        </picture>
                      </div>
                      <Card.Body className="p-0">
                        <div className="featured-title mt-4 mb-4">
                          <h5>{item.title}</h5>
                        </div>
                        <div className="featured-desc text-justify">
                          <p className="text justify-content">
                            {" "}
                            {item.content}
                          </p>
                        </div>
                      </Card.Body>
                      <Card.Footer className="p-0" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">
                          <Link to={item.buttonLink}> {item.buttonTitle}
                            <i className="ti ti-angle-double-right" />
                          </Link>
                        </a>
                      </Card.Footer>
                    </Card>
                  </Link>
                </div>
              )
            }
            )}
          </div>
        </div>
      </section>

      {/* aboutus-section */}
      <section className="ttm-row clearfix section1-content">
        <div className="container-xl">
          <div className="row no-gutters align-items-start">
            <div className="col-lg-6 res-991-text-center res-991-mb-40">
              <div className="ttm_single_image-wrapper" style={{ marginTop: 8 }}>
                <picture>
                  <img
                    className="img-fluid image-radious"
                    src="images/about-us.avif"
                    title="single-img-five"
                    alt="single-img-five"
                    loading="lazy"
                  />
                </picture>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="aboutus-spacing-2">

                <div className="section-title with-desc clearfix">
                  <div className="title-header mb-10">
                    <h2 className="title">
                      About Us
                    </h2>
                    <div className="under-title"></div>
                  </div>
                  <div className="title-desc text-justify line-space1 pt-4 pb-3">
                    <p className="line-space1">
                      {/* <span className='cog'>COGN<span className='cog1'>iTUDE</span> </span> */}
                      Cognitude, with over two decades of expertise, has established itself as a global leader in providing innovative IT solutions. We partner with businesses worldwide to deliver cutting-edge technological solutions, including AI, ML, and RPA.
                    </p>
                    <p className="line-space1">
                      Our deep understanding of specific industries, such as Telecommunication, Retail, Banking & Financial Services, enables us to tailor our solutions to meet the unique needs of each sector. We specialize in helping businesses transition online seamlessly, ensuring transparency and cost efficiency.
                    </p>
                  </div>
                </div>
                <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right">
                  <Link to="/about-us"> Know More {" "}
                    <i className="ti ti-angle-double-right" />
                  </Link>
                </a>
                {/* <a className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white ">
                    <Link to="/about-us"> Know More {" "}
                      <i className="ti ti-arrow-right ml-4 " />
                    </Link>
                  </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ttm-row clearfix section2-content">
        <div className="container-xl">
          <div className="row no-gutters align-items-start">
            <div className="col-lg-6 res-991-mb-40">
              <div className="section-title with-desc clearfix">
                <div className="title-header mb-10">
                  <h2 className="title">
                    About Us
                  </h2>
                  <div className="under-title"></div>
                </div>
                <div className="ttm_single_image-wrapper pt-4 pb-3">
                  <picture>
                    <img
                      className="img-fluid image-radious"
                      src="images/about-us.avif"
                      title="single-img-five"
                      alt="single-img-five"
                      loading="lazy"
                    />
                  </picture>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="aboutus-spacing-2">
                <div className="title-desc text-justify line-space1 ">
                  <p className="line-space1">
                    {/* <span className='cog'>COGN<span className='cog1'>iTUDE</span> </span> */}
                    Cognitude, with over two decades of expertise, has established itself as a global leader in providing innovative IT solutions. We partner with businesses worldwide to deliver cutting-edge technological solutions, including AI, ML, and RPA.
                  </p>
                  <p className="line-space1">
                    Our deep understanding of specific industries, such as Telecommunication, Retail, Banking & Financial Services, enables us to tailor our solutions to meet the unique needs of each sector. We specialize in helping businesses transition online seamlessly, ensuring transparency and cost efficiency.
                  </p>
                </div>
              </div>
              <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right">
                <Link to="/about-us"> Know More {" "}
                  <i className="ti ti-angle-double-right" />
                </Link>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* career section */}
      <section className="ttm-row clearfix section1-content">
        <div className="container-xl">
          <div className="row no-gutters align-items-start">
            <div className="col-lg-6 res-991-mb-40">
              <div className="section-title with-desc clearfix">
                <div className="title-header mb-10">
                  <h2 className="title">
                    Careers
                  </h2>
                  <div className="under-title"></div>
                </div>
                <div className="title-desc text-justify line-space1 pt-4 pb-3">
                  <p className="line-space1">
                    Cognitude offers exciting career opportunities for talented individuals seeking to make a significant impact in the IT industry. Join our team of experts and work on cutting-edge projects, collaborate with global clients, and develop your skills in emerging technologies. We provide a supportive and inclusive work environment, fostering innovation and professional growth.
                  </p>
                  <p className="line-space1">
                    Join our team of innovative minds and passionate individuals at Cognitude. As a leading IT company, we're dedicated to pushing the boundaries of technology and delivering exceptional solutions. We offer a collaborative work environment, opportunities for professional growth, and the chance to be part of something truly groundbreaking. If you're a talented and driven professional looking to make a significant impact, we invite you to explore our career opportunities and discover your next adventure.
                  </p>
                </div>
              </div>
              <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right">
                <Link to="/careers"> Search Early Career Jobs{" "}
                  <i className="ti ti-angle-double-right" />
                </Link>
              </a>
              {/* <a className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white ">
                  <Link to="/careers"> Search Early Career Jobs{" "}{" "}
                    <i className="ti ti-arrow-right ml-4 " />
                  </Link>
                </a> */}
            </div>

            <div className="col-lg-6">
              <div className="aboutus-spacing-2">
                <div className="ttm_single_image-wrapper" style={{ marginTop: 8 }}>
                  <picture>
                    <img
                      className="img-fluid image-radious"
                      src="images/career.avif"
                      title="single-img-five"
                      alt="single-img-five"
                      loading="lazy"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ttm-row clearfix section2-content">
        <div className="container-xl">
          <div className="row no-gutters align-items-start">
            <div className="col-lg-6 res-991-mb-40">
              <div className="section-title with-desc clearfix">
                <div className="title-header mb-10">
                  <h2 className="title">
                    Careers
                  </h2>
                  <div className="under-title"></div>
                </div>
                <div className="ttm_single_image-wrapper pt-4 pb-3">
                  <picture>
                    <img
                      className="img-fluid image-radious"
                      src="images/career.avif"
                      title="single-img-five"
                      alt="single-img-five"
                      loading="lazy"
                    />
                  </picture>
                </div>
              </div>

              {/* <a className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white ">
                  <Link to="/careers"> Search Early Career Jobs{" "}{" "}
                    <i className="ti ti-arrow-right ml-4 " />
                  </Link>
                </a> */}
            </div>

            <div className="col-lg-6">
              <div className="aboutus-spacing-2">
                <div className="title-desc text-justify line-space1 ">
                  <p className="line-space1">
                    Cognitude offers exciting career opportunities for talented individuals seeking to make a significant impact in the IT industry. Join our team of experts and work on cutting-edge projects, collaborate with global clients, and develop your skills in emerging technologies. We provide a supportive and inclusive work environment, fostering innovation and professional growth.
                  </p>
                  <p className="line-space1">
                    Join our team of innovative minds and passionate individuals at Cognitude. As a leading IT company, we're dedicated to pushing the boundaries of technology and delivering exceptional solutions. We offer a collaborative work environment, opportunities for professional growth, and the chance to be part of something truly groundbreaking. If you're a talented and driven professional looking to make a significant impact, we invite you to explore our career opportunities and discover your next adventure.
                  </p>
                </div>
              </div>
              <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right">
                <Link to="/careers"> Search Early Career Jobs{" "}
                  <i className="ti ti-angle-double-right" />
                </Link>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
