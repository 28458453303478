import React, { useEffect } from 'react';

import Header from '../layout/Header';
import { Footer } from '../layout/Footer';

export default function AboutUs(props) {
  const { REACT_APP_CGN_API_URL, REACT_APP_BEARER_TOKEN } = process.env;

  const product = props.location.state;

  console.log(product);

  useEffect(() => {
    const imagesToPreload = [
      "images/aboutus.avif",
      "images/about-us2.avif",
    ];

    imagesToPreload.forEach((image) => {
      const preloadLink = document.createElement("link");
      preloadLink.rel = "preload";
      preloadLink.as = "image";
      preloadLink.href = image;
      document.head.appendChild(preloadLink);

      return () => {
        document.head.removeChild(preloadLink);
      };
    });
  }, []);

  return (
    <>
      <div className="site-main">
        <Header />
        {/* page-title */}

          <div className="ttm-page-title-row">
            <div className="container-xl">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-box text-center">
                    <div className="page-title-heading">
                      <h1 className="title">About Us</h1>
                    </div>
                  </div>
                </div>{/* /.col-md-12 */}
              </div>{/* /.row */}
            </div>{/* /.container */}
          </div>

        {/* aboutus-section */}
        <section className="ttm-row clearfix ">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-text-center res-991-mb-20" style={{ marginTop: 5 }}>
                <div className="ttm_single_image-wrapper">
                  <picture>
                    <img
                      className="img-fluid image-radious"
                      src="images/aboutus.avif"
                      title="single-img-five"
                      alt="single-img-five"
                      loading='lazy'
                    />
                  </picture>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2">

                  <div className="section-title with-desc clearfix">
                    <div className="title-desc text-justify">
                      <p>
                        Cognitude, with over two decades of expertise, has established itself as a global leader in providing innovative IT solutions. We partner with businesses worldwide to deliver cutting-edge technological solutions, including AI, ML, and RPA.
                      </p>
                      <p>
                        Our deep understanding of specific industries, such as Telecommunication, Retail, Banking & Financial Services, enables us to tailor our solutions to meet the unique needs of each sector. We specialize in helping businesses transition online seamlessly, ensuring transparency and cost efficiency.
                      </p>
                      <p>
                        At Cognitude, we believe that our success is driven by our people. We are committed to sourcing the right talent for the right skills requirements and nurturing relevant skills in cutting-edge technologies.
                      </p>
                      <p>
                        Our focus on process optimization ensures that our clients benefit from efficient and effective solutions. We are dedicated to providing exceptional support and driving client success through our innovative approach.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* why cognitude section */}
        <section className="ttm-row clearfix pb-5 section1-content">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-mb-20">
                <div className="section-title with-desc clearfix">
                  <div className="title-header mb-10">
                    <h2 className="title">
                      Why Cognitude
                    </h2>
                    <div className="under-title"></div>
                  </div>
                  <div className="title-desc text-justify pt-4">
                    <p>
                      At Cognitude, we are dedicated to helping businesses thrive in the digital age. We provide comprehensive support to bring your vision to life, from concept to launch. Whether you need a custom app for ecommerce, food delivery, or any other industry, we offer solutions that exceed your expectations. Our unwavering commitment to excellence and our ability to adapt to diverse needs make us the ideal partner for your digital transformation journey.
                    </p>
                  </div>
                  <div className="row no-gutters mt-35">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ecommerce Application Development</span></li>
                        <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Enterprise Application Development</span></li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Web Development & Maintenance</span></li>
                        <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Mobile Application Development</span></li>
                        {/* <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Industry - Specific Business Solutions</span></li>
                              <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Service - Specific Consulting</span></li>
                              <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Talent Transformation</span></li>
                              <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Resourcing</span></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2" style={{ marginTop: 8 }}>
                  <div className="ttm_single_image-wrapper">
                    <picture>
                      <img
                        className="img-fluid image-radious"
                        src="images/about-us2.avif"
                        title="single-img-five"
                        alt="single-img-five"
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row clearfix pb-5 section2-content">
          <div className="container-xl">
            <div className="row no-gutters align-items-start">
              <div className="col-lg-6 res-991-mb-20">
                <div className="section-title with-desc clearfix">
                  <div className="title-header mb-10">
                    <h2 className="title">
                      Why Cognitude
                    </h2>
                    <div className="under-title"></div>
                  </div>
                  <div className="ttm_single_image-wrapper pt-4">
                    <picture>
                      <img
                        className="img-fluid image-radious"
                        src="images/about-us2.avif"
                        title="single-img-five"
                        alt="single-img-five"
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="aboutus-spacing-2" style={{ marginTop: 8 }}>
                <div className="title-desc text-justify">
                    <p>
                      At Cognitude, we are dedicated to helping businesses thrive in the digital age. We provide comprehensive support to bring your vision to life, from concept to launch. Whether you need a custom app for ecommerce, food delivery, or any other industry, we offer solutions that exceed your expectations. Our unwavering commitment to excellence and our ability to adapt to diverse needs make us the ideal partner for your digital transformation journey.
                    </p>
                  </div>
                  <div className="row no-gutters mt-35">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ecommerce Application Development</span></li>
                        <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Enterprise Application Development</span></li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Web Development & Maintenance</span></li>
                        <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Mobile Application Development</span></li>
                        {/* <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Industry - Specific Business Solutions</span></li>
                              <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Service - Specific Consulting</span></li>
                              <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Talent Transformation</span></li>
                              <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Resourcing</span></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

